<br>
<div class="card">
  <div class="bg-white bg-auto">
    <div class="text-center" >
      <h4 style="background-color:gray ;">Registration Form</h4>
<br>
    </div>
    <br>
<div class="col-md-10 d-flex justify-content-center">
  <div class="container">
    <form class="form-horizontal" role="form" autocomplete="off" [formGroup]="registerForm" (ngSubmit)="postdata()">
      <div class="form-row">
        <div class="form-group col-md-4" >
          <label for="Name">Name</label>
          <input type="text" id="txtName" formControlName="name" placeholder="Name" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.name.errors }" (keypress)="keyPressAlphaWithSpace($event)"
          autofocus>
        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
          <div *ngIf="f.name.errors.required">Name is required</div>
        </div>
        </div>
        <div class="form-group col-md-4">
          <label for="MobileNumber" class="control-label">MobileNo</label>
          <input type="text" id="txtmob" maxlength="10" placeholder="Mobile Number" formControlName="mob"
             class="form-control"
             [ngClass]="{ 'is-invalid': submitted && f.mob.errors }" (keypress)="keyPressNumbers($event)" class="form-control"
             autofocus>
           <div *ngIf="submitted && f.mob.errors" class="invalid-feedback">
             <div *ngIf="f.mob.errors.required">Mobile Number is required</div>
             <div *ngIf="f.mob.errors.minlength">MobileNo should be 10 digits</div>
           </div>
        </div>
        <div class="form-group col-md-4">
          <label for="email" class="control-label">Email</label>
          <input type="email" id="txtemail" placeholder="Email" class="form-control" formControlName="email"
            name="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
              <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
            </div>
        </div>
        <div class="form-group col-md-4">
          <label for="technology" class="control-label">Qualification</label>
          <select name="source" id="ddlsource" class="form-control" >
            <option value="BSC">Bsc</option>
            <option value="MSC">Msc</option>
            <option value="MCA">MCA</option>
            <option value="BTECH">BTech</option>
            <option value="Others">Others</option>
          </select>
        </div>
        <div class="form-group col-md-4">
          <!-- <label for="Name">Communication Rating (Out Of 10)</label>
          <input type="text" id="txtCommunication" formControlName="name" placeholder="Rating" class="form-control"> -->
          <!-- <div class="form-group col-md-4"> -->
            <label for="technology" class="control-label">Technology</label>
            <select name="technology" id="technology" class="form-control">
              <option value="1">Back-End Developer</option>
              <option value="2">Front-End Developer</option>
              <option value="3">QA Tester</option>
              <option value="4">Devops Engineer</option>
            </select>
          <!-- </div> -->
         
        </div>
        <div class="form-group col-md-4">
          <label for="experience" class="control-label">Experience</label>
          <input type="text" id="txtexperience" placeholder="experience" formControlName="exp"
            (keypress)="keyPressNumbersDecimal($event)" [ngClass]="{ 'is-invalid': submitted && f.exp.errors }"
            class="form-control">
          <div *ngIf="submitted && f.exp.errors" class="invalid-feedback">
            <div *ngIf="f.exp.errors.required">Experience is required</div>
          </div>
        </div>
        <div class="form-group col-md-4">
          <!-- <label for="Name">Logical Rating (Out Of 10)</label>
          <input type="text" id="txtLogical" formControlName="name" placeholder="Rating" class="form-control"> -->
          <!-- <div class="form-group col-md-6"> -->
            <label for="uploadresume" class="control-label">Upload Resume</label>
  
            <input type="file" id="myFile" name="filename" class="form-control" formControlName="uploadresume"
              [ngClass]="{ 'is-invalid': submitted && f.uploadresume.errors }"
              (change)="onSelectFile($event.target.files)">
            <div *ngIf="submitted && f.uploadresume.errors" class="invalid-feedback">
              <div *ngIf="f.uploadresume.errors.required">Resume is required</div>
            </div>
          <!-- </div> -->
        </div>
        <!-- <div class="form-group col-md-4">
          <label for="Name">Coding Rating (Out Of 10)</label>
          <input type="text" id="txtCoding" formControlName="name" placeholder="Rating" class="form-control">

        </div> -->
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-md-9">
          </div>
          <div class="col-md-3" style="right: -35px;position: absolute;margin-top: -13px;">
            <button type="submit" mat-raised-button color="primary">
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
    </div>
</div>
