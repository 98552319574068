import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-new-candidate',
  templateUrl: './new-candidate.component.html',
  styleUrls: ['./new-candidate.component.css']
})
export class NewCandidateComponent implements OnInit {
  loading: boolean;
  fileToUpload: any;
  fileData: any;
  registerForm: FormGroup;
  displaysourcename: boolean = false;
  submitted = false;
  constructor(private http: HttpClient, private formBuilder: FormBuilder) { }
  get f() { return this.registerForm.controls; }
  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      mob: ['', [Validators.required, Validators.minLength(10)]],
      email: ['', [Validators.required, Validators.email]],
      // technology:['', [Validators.required]],
      exp: ['', Validators.required],
      uploadresume: ['', Validators.required],
    })
  }
  postdata() {
    this.submitted = true;

    if (this.registerForm.invalid) {

      return;
    }
    var name = (<any>(document.getElementById('txtName'))).value;
    var mobile = (<any>(document.getElementById('txtmob'))).value;
    var email = (<any>(document.getElementById('txtemail'))).value;
    var qualification = (<any>(document.getElementById('ddlsource'))).value;
    var technology = (<any>(document.getElementById('technology'))).value;
    var exp = (<any>(document.getElementById('txtexperience'))).value;
    // var communication = (<any>(document.getElementById('txtCommunication'))).value;
    // var logical = (<any>(document.getElementById('txtLogical'))).value;
    // var coding = (<any>(document.getElementById('txtCoding'))).value;
    var myDate = new Date().toISOString();
    var datetimevalue = myDate.substring(0, myDate.length - 1);
    var result = { UserName: name, MobileNo: mobile, Email: email,Experience: exp, CreatedDate: datetimevalue, ExamStatus: 1,Source:6,SourceName:'Naresh It',Technology:technology,Qualification:qualification,};
    this.http.post<any>('https://ariqtonlineexamappapi.azurewebsites.net/api/User', result).subscribe(data => {
        if (data.userId > 0) {

          this.http.post('https://ariqtonlineexamapi.azurewebsites.net/api/User/savefile/' + data.userId, this.fileData)
          .subscribe(event => {


          });
        this.loading = false;
        alert("Added successfully");


          this.http.post<any>('https://ariqtonlineexamappapi.azurewebsites.net/api/User/sendemail', data ).subscribe(data => {
            alert("Successfully Registered, Please check your Email");
            window.self.close();
          },
          )
        }
        else {
          alert("This candidate is already exists");
        }

      },
      )
    }



    onSelectFile(files: FileList) {


      this.fileToUpload = true;
      if (files.length === 0) {
        return;
      }
      let fileToUpload = <File>files[0];
      const formData = new FormData();
      formData.append('file', fileToUpload, fileToUpload.name);
      this.fileData = formData;
  
    }
  
    selectSourceType(){
      var sourcetype = (<any>(document.getElementById('ddlsource'))).value;
      if (sourcetype == 6) {
        this.displaysourcename = true;
      }
      else
      {
        this.displaysourcename = false;
      }
    }
    keyPressAlphaWithSpace(event) {

      var inp = String.fromCharCode(event.keyCode);
  
      if (/^[a-zA-Z\s]*$/.test(inp)) {
        return true;
      } else {
        event.preventDefault();
        return false;
      }
    }
    keyPressAlphaWithSpaceAndNumber(event) {
  
      var inp = String.fromCharCode(event.keyCode);
  
      if (/^[a-zA-Z\s]*$/.test(inp)) {
        return true;
      } else {
        event.preventDefault();
        return false;
      }
    }
    keyPressNumbers(event) {
      var charCode = (event.which) ? event.which : event.keyCode;
      // Only Numbers 0-9
      if ((charCode < 48 || charCode > 57)) {
        event.preventDefault();
        return false;
      } else {
        return true;
      }
    }
    keyPressNumbersDecimal(event) {
      var charCode = (event.which) ? event.which : event.keyCode;
      if (charCode != 46 && charCode > 31
        && (charCode < 48 || charCode > 57)) {
        event.preventDefault();
        return false;
      }
      return true;
    }
  }
